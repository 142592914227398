import {Action, Module, Mutation, VuexModule} from "vuex-module-decorators";
import {Deity as DeityData} from "@/api/DeityApi";

@Module({ namespaced: true, name: 'deity' })
export default class Deity extends VuexModule {
    public deities: Record<number, DeityData[]> = {};

    @Mutation
    public setDeities(deities: Record<number, DeityData[]>): void {
        this.deities = deities;
    }

    @Action
    public updateDeities(deities: Record<number, DeityData[]>): void {
        this.context.commit('setDeities', deities);
    }

}
