

















































































import api, {AuthResponse, Lore20User} from '@/api/UsersApi'
import { namespace } from "vuex-class";
import notificationUtil from "@/notifications/NotificationUtil";
import Component from "vue-class-component";
import {Prop, Vue} from "vue-property-decorator";
import commerceApi from "@/api/CommerceApi";

const user = namespace("User")
const commerce = namespace("Commerce")

@Component({
  components: {}
})
export default class SignupView extends Vue {

  @Prop({ required: false, default: false }) defaultToSignup!: boolean;
  @Prop({ required: false, default: '/games' }) forwardRoute!: string
  @Prop({ required: false, default: true }) forwardToAnotherPage!: boolean;

  @user.State
  token!: string;

  @user.Mutation
  setTokens!: (newToken: AuthResponse) => void; // eslint-disable-line

  @user.Mutation
  setUsername!: (username: string) => void; // eslint-disable-line

  @user.Mutation
  setStripeId!: (stripeId: string) => void; // eslint-disable-line

  @user.Mutation
  setUser!: (user: Lore20User) => void; // eslint-disable-line

  @commerce.Mutation
  setSubscription!: (subscribed: boolean) => void; // eslint-disable-line

  @commerce.State
  subscribed!: boolean

  newUser = false
  // @ts-ignore
  userInfo = {
    id: -1,
    email: "",
    username: "",
    password: "",
    firstName: "",
    lastName: "",
    stripeId: "",
    verificationCode: "",
    trialUsed: false
  }
  submitting = false;
  signupStep = 1;

  created() {
    this.newUser = this.defaultToSignup;
  }

  startCreateUser() {
    if (this.loadingOrUserInfoIsBlank) {
      return;
    }
    this.submitting = true
    api.verify(this.userInfo).then(() => {
      this.submitting = false;
      this.signupStep = 2;
    }).catch(() => {
      notificationUtil.error('An error occurred. Please check your information and try again.');
      this.submitting = false;
    });
  }

  startRecoverPassword() {
    this.$router.push('/recover-password')
  }

  createNewUser() {
    if (this.loadingOrUserInfoIsBlank) {
      return;
    }
    this.submitting = true;
    api.signUp(this.userInfo).then((token: any) => {
      this.$gtag.event('conversion', { 'send_to': 'AW-11012136827/1B8nCOHGlIcYEPu-_4Ip' });
      this.$gtag.event('sign_up', {
        event_category: 'engagement',
        event_label: 'method'
      });
      this.successfulLogin(token);
    }).catch(() => {
      notificationUtil.error("Failed to register account")
    }).finally(() => {
      this.submitting = false;
    });
  }

  authenticateExistingUser() {
    if (this.loadingOrUserInfoIsBlank) {
      return;
    }
    this.submitting = true;
    api.login(this.userInfo)
        .then((tokens: AuthResponse) => {
          this.successfulLogin(tokens);
          commerceApi.isSubscribed().then((subscribed: boolean) => {
            this.setSubscription(subscribed)
          });
        })
        .catch(() => {
          notificationUtil.error("Entered username or password is incorrect")
        }).finally(() => {
          this.submitting = false;
        });
  }

  successfulLogin(tokens: AuthResponse) {
    this.setTokens(tokens);
    api.info().then((user: Lore20User) => {
      this.setUser(user);
    });
    commerceApi.isSubscribed().then((subscribed: boolean) => {
      this.setSubscription(subscribed);
    }).catch(() => {});
    notificationUtil.success("Signed In");
    if (this.forwardToAnotherPage) {
      this.$router.push(this.forwardRoute);
    }
    this.$gtag.event('login', {
      event_category: 'engagement',
      event_label: 'method',
    });
  }

  get loadingOrUserInfoIsBlank(): boolean {
    if (this.newUser) {
      return this.userInfo.email === ''
          || this.userInfo.username === ''
          || this.userInfo.password === ''
          || this.userInfo.firstName === ''
          || this.userInfo.lastName === ''
          || this.submitting
    }
    else {
      return this.userInfo.email === ''
          || this.userInfo.password === ''
          || this.submitting
    }
  }

}
