




















import {Component, Prop, Vue} from "vue-property-decorator";
import {Lore} from "@/api/LoreApi";

@Component
export default class LoreCard extends Vue {
  @Prop({ required: true }) lore!: Lore;
}
