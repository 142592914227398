







































































import {Prop, Vue} from "vue-property-decorator";
import Component from "vue-class-component";
import {Person} from "@/api/PersonApi";

@Component
export default class PersonCard extends Vue {

  @Prop({ required: true }) imageUrl!: string;
  @Prop({ required: true }) person!: Person;
  @Prop({ required: false, default: "200" }) size!: string;

  get nudgeSize(): string {
    return (Number.parseInt(this.size) - 2).toString()
  }

  get scalar(): string {
    return (Number.parseInt(this.size) / 200).toString()
  }

}
