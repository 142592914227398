import {Action, Module, Mutation, VuexModule} from "vuex-module-decorators";

@Module({ namespaced: true, name: 'commerce' })
export default class Commerce extends VuexModule {
    public subscribed = false;

    @Mutation
    public setSubscription(subscribed: boolean): void {
        this.subscribed = subscribed;
    }
    @Action
    public updateUser(subscribed: boolean): void {
        this.context.commit('setSubscription', subscribed);
    }
}
