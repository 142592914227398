import {Action, Module, Mutation, VuexModule} from "vuex-module-decorators";
import {Person as PersonData} from "@/api/PersonApi";

@Module({ namespaced: true, name: 'person' })
export default class Person extends VuexModule {
    public people: Record<number, PersonData[]> = {};

    @Mutation
    public setPeople(people: Record<number, PersonData[]>): void {
        this.people = people;
    }

    @Action
    public updatePeople(people: Record<number, PersonData[]>): void {
        this.context.commit('setPeople', people);
    }

}
