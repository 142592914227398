import axios from "axios";
import {authenticated} from "@/api/hooks";
import notificationUtil from "@/notifications/NotificationUtil";
const login = axios.create({
    baseURL: `${process.env.VUE_APP_HOSTNAME}`,
    headers: {
        "Content-type": "application/json",
    }
});
const users = authenticated(axios.create({
    baseURL: `${process.env.VUE_APP_HOSTNAME}/api/users`,
    headers: {
        "Content-type": "application/json"
    }
}));
const auth = axios.create({
    baseURL: `${process.env.VUE_APP_HOSTNAME}/api/auth`,
    headers: {
        "Content-type": "application/json"
    }
})

export type Lore20User = {
    id: number,
    email: string,
    username: string,
    password: string,
    firstName: string,
    lastName: string,
    stripeId: string,
    trialUsed: boolean
}

export type AuthResponse = {
    accessToken: string,
    refreshToken: string
}

class UsersApi {

    verify(user: Lore20User): Promise<void> {
        return users.post('/verify', user)
    }

    signUp(user: Lore20User): Promise<AuthResponse> {
        return users.post('/create', user).then(() => {
            return this.login(user);
        }).catch(() => {
            return Promise.reject();
        });
    }

    login(user: Lore20User): Promise<AuthResponse> {
        return login.post('/login', { email: user.email, password: user.password }).then((response: any) => {
            if (response.headers.accesstoken !== null
                && response.headers.accesstoken !== ''
                && response.headers.refreshtoken !== null
                && response.headers.refreshtoken !== ''
            ) {
                return {
                    accessToken: response.headers.accesstoken,
                    refreshToken: response.headers.refreshtoken
                }
            }
            return Promise.reject();
        });
    }

    update(email: string, username: string, oldPassword: string, newPassword: string): Promise<void> {
        return users.post('/update', { email, username, oldPassword, newPassword })
    }

    info(): Promise<Lore20User> {
        return users.get('/info').then((response: any) => {
            return response.data;
        });
    }

    refresh(refreshToken: string, force: boolean = false): Promise<AuthResponse> {
        return auth.post('/refresh', { refreshToken, force }).then((response: any) => {
            return response.data;
        });
    }

    sendRecoveryLink(email: string): Promise<void> {
        return auth.post('/recover', { email });
    }

    getRecoveryLinkValidity(recoveryId: string): Promise<void> {
        return auth.get('/recover/' + recoveryId)
    }

    resetPassword(recoveryId: string, newPassword: string): Promise<void> {
        return auth.post('/recover/' + recoveryId, { newPassword });
    }
}
export default new UsersApi();