







































































import {Component, Prop, Vue} from "vue-property-decorator";
import {Creature} from "@/api/CreatureApi";
import imageApi from "@/api/ImageApi";

@Component
export default class CreatureCard extends Vue {
  @Prop({ required: true }) creature!: Creature;
  @Prop({ required: false, default: null }) imageOverride!: string;

  imageUrl: string = "";

  created() {
    this.getPortrait();
  }

  getPortrait() {
    if (this.creature.portrait != null && this.creature.portrait.id !== -1) {
      imageApi.get(this.creature.portrait.id).then((dataUrl: string) => {
        this.imageUrl = `data:image/jpeg;base64,${dataUrl}`
      });
    }
  }

  get imageForCreature(): string {
    if (this.imageOverride) {
      return this.imageOverride;
    } else {
      return this.imageUrl;
    }
  }
}
