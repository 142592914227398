







































































import {Prop, Vue} from "vue-property-decorator";
import Component from "vue-class-component";
import projectApi, {Project} from "@/api/ProjectApi";
import {Person} from "@/api/PersonApi";
import {namespace} from "vuex-class";
import {Session} from "@/api/SessionApi";
import {Quest} from "@/api/QuestApi";
import imageApi from "@/api/ImageApi";

const people = namespace('Person');
const session = namespace('Session');
const quest = namespace('Quest');

@Component
export default class ProjectCard extends Vue {
  @Prop({ required: true }) project!: Project

  @people.State
  people!: Record<number, Person[]>;
  @session.State
  sessions!: Record<number, Session[]>;
  @quest.State
  quests!: Record<number, Quest[]>;

  imageUrl: string | null = "";
  collaborators: string[] = [];

  created() {
    this.getCoverPhoto();
    this.getCollaborators();
  }

  get numberOfPeople() {
    return (this.people[this.project.id] != undefined) ? this.people[this.project.id].length : 0;
  }

  get numberOfSessions() {
    return (this.sessions[this.project.id] != undefined) ? this.sessions[this.project.id].length : 0;
  }

  get numberOfQuests() {
    return (this.quests[this.project.id] != undefined) ? this.quests[this.project.id].length : 0;
  }

  getCoverPhoto() {
    if (this.project.portrait == null) {
      this.imageUrl = null;
    } else {
      imageApi.get(this.project.portrait.id).then((dataUrl: string) => {
        this.imageUrl = 'data:image/jpeg;base64,' + dataUrl;
      });
    }
  }

  getCollaborators() {
    projectApi.collaborators(this.project.id).then((collaborators) => {
      this.collaborators = collaborators.map((item) => item.slice(0, 2).toUpperCase());
    })
  }

  get numEntriesPerLine(): number {
    const screenSize = this.$vuetify.breakpoint.name
    if (screenSize === 'xs') {
      return 1
    } else if (screenSize === 'sm') {
      return 2
    } else {
      return 3
    }
  }

  get cardWidth(): string {
    const entriesPerLine = this.numEntriesPerLine;
    return `${Math.round(100 / entriesPerLine) - 2}%`;
  }

  get cardLayout(): string {
    return (this.numEntriesPerLine) ? 'flex-row' : 'flex-column';
  }

}
