import {AxiosInstance} from "axios";
import store from "@/store";
import notificationUtil from "@/notifications/NotificationUtil";
import usersApi from "@/api/UsersApi";

export function authenticated(instance: AxiosInstance): AxiosInstance {
    instance.interceptors.request.use(function (config) {
        const token = store.state.User.token
        if (config.headers != null) {
            config.headers.Authorization =  token;
        }
        return config;
    });
    instance.interceptors.response.use((response) => {
        return response
    }, (error) => {
        if (error.response.status === 403) {
            const refreshToken = store.state.User.refresh;
            usersApi.refresh(refreshToken).then((response: any) => {
                store.state.User.token = response.accessToken;
                store.state.User.refresh = response.refreshToken;
            }).catch(() => {
                store.state.User.token = '';
                store.state.User.refresh = '';
            });
        }
        if (error.response.status === 426) {
            notificationUtil.error('The project owner has hit the storage limit on their account. They should consider upgrading to Premium for more storage space if they have not already done so.')
        }
        return Promise.reject(error);
    })
    return instance;
}
