


















































































































































import {Vue} from "vue-property-decorator";
import Component from "vue-class-component";
import projectApi, {Project} from "@/api/ProjectApi";
import CreateProjectModal from "@/components/modal/CreateProjectModal.vue";
import ProjectCard from "@/components/card/ProjectCard.vue";
import {namespace} from "vuex-class";
import personApi, {Person} from "@/api/PersonApi";
import sessionApi, {Session} from "@/api/SessionApi";
import DataLoader from "@/util/DataLoader";
import {Lore20User} from "@/api/UsersApi";

const project = namespace('Project');
const people = namespace('Person');
const session = namespace('Session');
const commerce = namespace('Commerce');
const userStore = namespace('User');

@Component({
  name: 'projects-view',
  components: {ProjectCard, CreateProjectModal}
})
export default class ProjectsView extends Vue {
  @project.State
  projects!: Record<number, Project[]>;
  @people.State
  people!: Record<number, Person[]>;
  @session.State
  sessions!: Record<number, Session[]>;
  @project.Mutation
  setProjects!: (projects: Record<number, Project[]>) => void; // eslint-disable-line
  @people.Mutation
  setPeople!: (people: Record<number, Person[]>) => void; // eslint-disable-line
  @session.Mutation
  setSessions!: (sessions: Record<number, Session[]>) => void // eslint-disable-line
  @commerce.State
  subscribed!: boolean;
  @userStore.State
  user!: Lore20User;


  editingProject = false;
  showDialog = false;
  model = [0, 1];
  bytesStored = 0;

  created() {
    this.loadAll();
  }

  loadProjects() {
    const projectLoader = new DataLoader<Project>(projectApi, this.projects, this.setProjects);
    projectLoader.getAll().then(() => {
      this.model = [];
      if (this.myProjects.length !== 0 && !this.model.includes(0)) {
        this.model.push(0)
      }
      if (this.sharedWithMe.length !== 0 && !this.model.includes(1)) {
        this.model.push(1);
      }
    });
    projectApi.storage(this.user.id).then((res) => {
      this.bytesStored = res;
    });
  }

  loadAll() {
    const loaders = [
      new DataLoader<Person>(personApi, this.people, this.setPeople),
      new DataLoader<Session>(sessionApi, this.sessions, this.setSessions)
    ]
    this.loadProjects();
    loaders.forEach((loader) => loader.getAll());
  }

  showProjectEditor(project: Project) {
    this.$router.push(`/games/${project.id}`)
  }

  get numEntriesPerLine(): number {
    const screenSize = this.$vuetify.breakpoint.name
    if (screenSize === 'xs') {
      return 1
    } else if (screenSize === 'sm') {
      return 2
    } else {
      return 3
    }
  }

  get cardLayout(): string {
    return (this.numEntriesPerLine) ? 'flex-row' : 'flex-column';
  }

  get myProjects(): Project[] {
    const output: Project[] = [];
    for (let projectsKey in this.projects) {
      const project = this.projects[projectsKey][0];
      if (project.owner.id === this.user.id) {
        output.push(project);
      }
    }
    return output;
  }

  get canCreateNewProjects(): boolean {
    return this.subscribed || this.myProjects.length < 3;
  }

  get sharedWithMe(): Project[] {
    const output: Project[] = [];
    for (let projectsKey in this.projects) {
      const project = this.projects[projectsKey][0];
      if (project.owner.id !== this.user.id) {
        output.push(project);
      }
    }
    return output;
  }

  get sharedWithMeDisabled(): boolean {
    return this.sharedWithMe.length === 0;
  }

  get myProjectsDisabled(): boolean {
    return this.myProjects.length === 0;
  }

  get kilobytesStored(): number {
    return this.bytesStored / 1024
  }

  get megabytesStored(): number {
    return this.kilobytesStored / 1024;
  }

  get gigabytesStored(): number {
    return this.megabytesStored / 1024;
  }

  get percentageUsed(): number {
    if (this.subscribed) {
      return 100 * (this.gigabytesStored / 10);
    } else {
      return 100 * (this.megabytesStored / 50);
    }
  }

  get storageBarColor(): string {
    if (this.percentageUsed < 60) {
      return 'light-blue';
    } else if (this.percentageUsed < 90) {
      return 'yellow darken-4';
    } else {
      return 'red';
    }
  }

  get numEntriesPerLineBlog(): number {
    const screenSize = this.$vuetify.breakpoint.name
    if (screenSize === 'xs') {
      return 1
    } else {
      return 2
    }
  }

  get cardWidthBlog(): string {
    return `${Math.round(100 / this.numEntriesPerLineBlog) - 2}%`
  }

}
