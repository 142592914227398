import { POSITION } from "vue-toastification";
import Vue from "vue";

class NotificationUtil {

    success(message: string) {
        Vue.$toast.success(message, {
            position: POSITION.BOTTOM_RIGHT,
            timeout: 5000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: false,
            closeButton: "button",
            icon: true,
            rtl: false
        });
    }

    info(message: string) {
        Vue.$toast.info(message, {
            position: POSITION.BOTTOM_RIGHT,
            timeout: 5000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: false,
            closeButton: "button",
            icon: true,
            rtl: false
        });
    }

    error(message: string) {
        Vue.$toast.error(message, {
            position: POSITION.BOTTOM_RIGHT,
            timeout: 5000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: false,
            closeButton: "button",
            icon: true,
            rtl: false
        });
    }

}

export default new NotificationUtil();