


































































import {Prop, Vue} from "vue-property-decorator";
import Component from "vue-class-component";
import {Organization} from "@/api/OrganizationApi";

@Component
export default class OrganizationCard extends Vue {

  @Prop({ required: true }) organization!: Organization;
  @Prop({ required: false }) imageUrl!: string;

}
