import {Action, Module, Mutation, VuexModule} from "vuex-module-decorators";
import {Session as SessionData} from "@/api/SessionApi";

@Module({ namespaced: true, name: 'session' })
export default class Session extends VuexModule {
    public sessions: Record<number, SessionData[]> = {};

    @Mutation
    public setSessions(sessions: Record<number, SessionData[]>): void {
        this.sessions = sessions;
    }

    @Action
    public updateSessions(sessions: Record<number, SessionData[]>): void {
        this.context.commit('setSessions', sessions);
    }

}
