import {Action, Module, Mutation, VuexModule} from "vuex-module-decorators";
import {Project as ProjectData} from "@/api/ProjectApi";

@Module({ namespaced: true, name: 'commerce' })
export default class Project extends VuexModule {
    public projects: Record<number, ProjectData[]> = {};

    @Mutation
    public setProjects(projects: Record<number, ProjectData[]>): void {
        this.projects = projects;
    }

    @Action
    public updateProjects(projects: Record<number, ProjectData[]>): void {
        this.context.commit('setProjects', projects);
    }

}
