import {Action, Module, Mutation, VuexModule} from "vuex-module-decorators";
import {AuthResponse, Lore20User} from "@/api/UsersApi";

@Module({ namespaced: true, name: 'user' })
export default class User extends VuexModule {
    public token = ''
    public refresh = ''
    public user: Lore20User = {
        id: -1,
        email: "",
        username: "",
        password: "",
        firstName: "",
        lastName: "",
        stripeId: "",
        trialUsed: false
    }

    @Mutation
    public setUser(user: Lore20User): void {
        this.user = user;
    }
    @Action
    public updateUser(user: Lore20User): void {
        this.context.commit('setUser', user);
    }
    @Mutation
    public setUsername(username: string): void {
        this.user.username = username;
    }
    @Action
    public updateUsername(username: string): void {
        this.context.commit('setUsername', username);
    }
    @Mutation
    public setTokens(tokens: AuthResponse): void {
        this.token = tokens.accessToken;
        this.refresh = tokens.refreshToken;
    }
    @Action
    public updateToken(token: string): void {
        this.context.commit('setToken', token);
    }
    @Mutation
    public setStripeId(stripeId: string): void {
        this.user.stripeId = stripeId;
    }
    @Action
    public updateStripId(stripeId: string): void {
        this.context.commit('setStripeId', stripeId);
    }
    @Mutation
    public logoutUser(): void {
        this.token = "";
        this.refresh = "";
    }
    get isLoggedIn(): boolean {
        return this.token !== null && this.token !== '';
    }
}