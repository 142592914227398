import {Action, Module, Mutation, VuexModule} from "vuex-module-decorators";
import {Lore as LoreData} from "@/api/LoreApi";

@Module({ namespaced: true, name: 'lore' })
export default class Lore extends VuexModule {
    public loreEntries: Record<number, LoreData[]> = {};

    @Mutation
    public setLoreEntries(loreEntries: Record<number, LoreData[]>): void {
        this.loreEntries = loreEntries;
    }

    @Action
    public updateLoreEntries(loreEntries: Record<number, LoreData[]>): void {
        this.context.commit('setLoreEntries', loreEntries);
    }

}
