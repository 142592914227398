import Vue from 'vue'
import Vuex from 'vuex'
import User from "@/store/modules/user";
import Commerce from "@/store/modules/commerce";
import Project from "@/store/modules/project";
import Person from "@/store/modules/person";
import Session from "@/store/modules/session";
import Quest from "@/store/modules/quest";
import Location from "@/store/modules/location";
import Organization from "@/store/modules/organization";
import Creature from "@/store/modules/creature";
import Deity from "@/store/modules/deity";
import Lore from "@/store/modules/lore";
import VuexPersistence from "vuex-persist";

Vue.use(Vuex)

const vuexSession = new VuexPersistence({
  storage: window.localStorage
});

export default new Vuex.Store({
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    User,
    Commerce,
    Project,
    Person,
    Session,
    Quest,
    Location,
    Organization,
    Creature,
    Deity,
    Lore
  },
  plugins: [vuexSession.plugin]
})
