






import Component from "vue-class-component";
import {Vue} from "vue-property-decorator";
import LandingPage from "@/components/LandingPage.vue";

@Component({
  components: {
    LandingPage
  },
})
export default class HomeView extends Vue {}
