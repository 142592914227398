export default class DataLoader<Type> {

    api!: any;
    entitySetter!: (entities: Record<number, Type[]>) => void; // eslint-diable-line
    entity!: Record<number, Type[]>

    constructor(api: any, entity: Record<number, Type[]>, entitySetter: (entities: Record<number, Type[]>) => void) {
        this.api = api
        this.entity = entity;
        this.entitySetter = entitySetter;
    }

    async getByProjectAndEntityId(projectId: number, entityId: number): Promise<Type> {
        if (this.entity) {
            // @ts-ignore
            return this.findByEntityId(entityId, this.entity[projectId]);
        }
        return this.getAll().then((result: Record<number, Type[]>) => {
            return this.findByEntityId(entityId, result[projectId]!!);
        })
    }

    async getAll(): Promise<Record<number, Type[]>> {
        return this.getRemoteData().then((result: Record<number, Type[]>) => {
            this.entitySetter(result);
            return result;
        });
    }

    async getRemoteData(): Promise<Record<number, Type[]>> {
        return await this.api.list();
    }

    findByEntityId(id: number, entities: Type[]): Type {
        if (entities != null) {
            // @ts-ignore
            return entities.find((entity: Type) => entity.id === id)!!;
        }
        // @ts-ignore
        return null;
    }

}