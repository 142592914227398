
































import {Prop, Vue} from "vue-property-decorator";
import Component from "vue-class-component";
import {Quest} from "@/api/QuestApi";

@Component
export default class QuestCard extends Vue {
  @Prop({ required: true }) projectId!: number;
  @Prop({ required: true }) quest!: Quest;

  statusInfo = {
    'IN_PROGRESS': {
      text: 'In Progress',
      color: 'blue'
    },
    'NOT_STARTED': {
      text: 'Not Started',
      color: 'gray'
    },
    'COMPLETED': {
      text: 'Completed',
      color: 'green'
    }
  }

}
