import {authenticated} from "@/api/hooks";
import axios, {AxiosResponse} from "axios";
import {Lore20User} from "@/api/UsersApi";

export type Project = {
    id: number,
    owner: Lore20User,
    title: string,
    people: Person[],
    portrait: { id: number } | null,
}
export type Person = { id: number, age: number, race: string, gender: string, role: string, appearance: string, locationCurrent: string, locationMet: string, name: string }
export const PLACEHOLDER_PROJECT = {
    id: -1,
    title: '',
    owner: { id: -1, email: '', username: '', password: '', firstName: '', lastName: '', stripeId: '', trialUsed: false },
    people: [],
    portrait: { id: -1 }
};

const project = authenticated(
    axios.create({
        baseURL: `${process.env.VUE_APP_HOSTNAME}/api/project`
    })
);
const apiImage = authenticated(
    axios.create({
        baseURL: `${process.env.VUE_APP_HOSTNAME}/api/project`,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data'
        }
    })
);
class ProjectApi {

    create(title: string, image: Blob | null): Promise<string> {
        const form = new FormData()
        if (image !== null) {
            form.append('image', image, 'project-' + Date.now() + '.jpeg');
        }
        form.append(
            'project',
            new Blob([JSON.stringify({ title: title })], { type: "application/json" })
        );
        return apiImage.post('/create', form).then((res: AxiosResponse<string>) => {
            return res.data;
        });
    }

    update(id: number, title: string, usersToRemove: number[], image: Blob | null): Promise<string> {
        const form = new FormData()
        if (image !== null) {
            form.append('image', image, 'project-' + Date.now() + '.jpeg');
        }
        form.append(
            'project',
            new Blob([JSON.stringify({ projectId: id, title: title, usersToRemove: usersToRemove })], { type: "application/json" })
        );
        return apiImage.post('/update', form).then((res: AxiosResponse<string>) => {
            return res.data;
        });
    }

    delete(id: number): Promise<void> {
        return project.post('/delete', { projectId: id });
    }

    list(): Promise<Record<number, Project[]>> {
        return project.get('/list').then((res: AxiosResponse<Record<number, Project[]>>) => {
            return res.data;
        })
    }

    collaborators(id: number): Promise<string[]> {
        return project.get(`/${id}/users`).then((res) => {
            return res.data;
        })
    }

    userPermissions(id: number): Promise<Lore20User[]> {
        return project.get(`/${id}/user-permissions`).then((res) => {
          return res.data;
        });
    }

    storage(id: number): Promise<number> {
        return project.get('/storage').then((res) => {
            return res.data;
        });
    }
}
export default new ProjectApi();
