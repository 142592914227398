import {Action, Module, Mutation, VuexModule} from "vuex-module-decorators";
import {Quest as QuestData} from "@/api/QuestApi";

@Module({ namespaced: true, name: 'quest' })
export default class Quest extends VuexModule {
    public quests: Record<number, QuestData[]> = {};

    @Mutation
    public setQuests(quests: Record<number, QuestData[]>): void {
        this.quests = quests;
    }

    @Action
    public updateQuests(quests: Record<number, QuestData[]>): void {
        this.context.commit('setQuests', quests);
    }

}
