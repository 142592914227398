

















































import {Ref, Vue} from "vue-property-decorator";
import Component from "vue-class-component";
import projectApi from "@/api/ProjectApi";
import {Cropper} from "vue-advanced-cropper";
import 'vue-advanced-cropper/dist/style.css';
import notificationUtil from "@/notifications/NotificationUtil";
import ImageCropper from "@/util/ImageCropper";

@Component({
  name: 'create-project-modal',
  components: { Cropper }
})
export default class CreateProjectModal extends Vue {

  @Ref('cropper') cropper!: Cropper;

  projectName: string = "";
  image: File = new File([], "");
  imageCropper = new ImageCropper();
  submitting = false


  createProject() {
    let result = null;
    if (this.$refs.cropper) {
      result = this.cropper.getResult();
    }
    this.submitting = true;
    if (result !== null && result.canvas != null) {
      this.imageCropper.crop(result.canvas, (blob: any) => {
        projectApi.create(this.projectName, blob).then(() => {
          this.$emit('project-created');
          this.reset();
          this.$gtag.event('entity_create', {
            event_category: 'general',
            event_label: 'Project Created',
          });
        }).catch(() => {
          notificationUtil.error('An error occurred. Please try again.');
        }).finally(() => {
          this.submitting = false;
        });
      });
    } else {
      projectApi.create(this.projectName, null).then(() => {
        this.$emit('project-created');
        this.reset();
        this.$gtag.event('entity_create', {
          event_category: 'general',
          event_label: 'Project Created',
        });
      }).catch(() => {
        notificationUtil.error('An error occurred. Please try again.');
      }).finally(() => {
        this.submitting = false;
      });
    }
  }

  getImagePreview(file: File) {
    this.imageCropper.loadImage(file);
  }


  closed() {
    this.$emit('closed')
    this.reset();
  }

  reset() {
    this.projectName = "";
    this.image = new File([], "");
    this.imageCropper = new ImageCropper();
  }

}
