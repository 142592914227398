






















































































































































































































































































































































































import Vue from 'vue'
import SessionCard from "@/components/card/SessionCard.vue";
import Component from "vue-class-component";
import QuestCard from "@/components/card/QuestCard.vue";
import PersonCard from "@/components/card/PersonCard.vue";
import OrganizationCard from "@/components/card/OrganizationCard.vue";
import LocationCard from "@/components/card/LocationCard.vue";
import {namespace} from "vuex-class";
import CreatureCard from "@/components/card/CreatureCard.vue";
import DynamicMarquee from "vue-dynamic-marquee";
import SignupView from "@/views/user/SignupView.vue";
import LoreCard from "@/components/card/LoreCard.vue";

const user = namespace('User');
const commerce = namespace('Commerce');

// @ts-ignore
@Component({
  components: {
    LoreCard,
    SignupView,
    CreatureCard,
    LocationCard,
    OrganizationCard,
    PersonCard,
    QuestCard,
    SessionCard,
    DynamicMarquee
  },
  metaInfo() {
    return {
      meta: [
        { charset: 'utf-8' },
        { name: 'description', content: 'Lore 20 is a collaborative world-building application for game-masters and players alike that works out of the box so you can start writing as soon as you sign up.' }
      ]
    }
  }
})
export default class LandingPage extends Vue {
    @user.Getter
    isLoggedIn!: () => boolean;
    @commerce.State
    subscribed!: boolean;

    showSignupModal1 = false;
    showSignupModal2 = false;

    unlimitedGamesWebp = require('../assets/unlimited_games.webp');
    privateWebp = require('../assets/gm_only.webp');
    linkWebp = require('../assets/link.webp');
    searchWebp = require('../assets/search.webp');
    tavernWebp = require('../assets/tavern.webp');
    collaborateWebp = require('../assets/collaboration.webp');
    collabsWebp = require('../assets/collabs.webp');
    ralphWebp = require('../assets/ralph.webp');
    ignitoWebp = require('../assets/ignito.webp');
    sorenWebp = require('../assets/soren.webp');
    theOrderWebp = require('../assets/the_order.webp');
    aquasWebp = require('../assets/aquas.webp');
    theManagerWebp = require('../assets/the_manager.webp');
    session = {
          id: -1,
          title: { id: -1, state: "Session 32: Return to the Order" },
          date: { id: -1, state: "07/08/22" },
          body: { id: -1, state: "Last we left off, we had defeated <u>Karl von Helmstead</u>, destroyed his arcane form, " +
                "and driven <b>Nyer</b> off. It has been 6 months. We all had <a href='/'>goals of our own</a> during this time but have now" +
                " reconvened at the headquarters of <b>The Order</b>." },
          projectId: -1
    };
    quest = {
      id: -1,
      title: { id: -1, state: 'Escorting a Nobleman' },
      status: { id: -1, state: 'IN_PROGRESS' },
      questGiver: { id: -1, state: "Nobleman Etos" },
      reward: { id: -1, state: "10,000 GP" },
      statusType: { id: -1, state: "IN_PROGRESS" },
      details: { id: -1, state: "<b>Nobleman Etos</b> seeks escort to the plane of <u>Terra</u>. He worries that " +
            "one of the rival noble families has put a hit on his head and that they may plan to do it aboard the train. " }
    };
    ignito = {
      id: -1,
      name: {state:"Ignito", revisionNumber: 0 },
      age: {state: "30", revisionNumber:0},
      gender: {state: "Male", revisionNumber:0},
      role: {state: "Warlock", revisionNumber:0},
      race: {state: "Elemental", revisionNumber:0},
      details: {state:"Born in Ekamond, Ignito lived a quiet life before the death of his brother, Marino, at the hands of an evil mage. Swearing revenge, he struck a deal with a mysterious entity.", revisionNumber:0},
      backstory: {state:"", revisionNumber:0},
      goals: {state:"", revisionNumber:0},
      location: {state:"", revisionNumber:0},
      project: -1,
      portrait: { id: -1 }
    };
  organization = {
    id: -1,
    name: { state: "The Order", revisionNumber: -1 },
    details: { state: "<b>The Order</b> was originally founded by <u>Ebberath</u>, <u>Serevan</u>, and <u>Kari</u> as a " +
          "force of good to combat forces of evil in the darker ages of history. They operated originally throughout <a href='/'>The Fourth Great War of Mana</a>." +
          " Several centuries after their initial founding, their numbers dwindled to the point that <b>The Order</b> was shut down and thought lost. Our party" +
          " has since rediscovered and restarted this organization.", revisionNumber: -1 },
    goals: { state: "", revisionNumber: -1 },
    membership: { state: "", revisionNumber: -1 },
    portrait: { id: -1 },
    project: -1
  }
  location = {
    id: -1,
    name: { state: 'Aquas', revisionNumber: -1 },
    details: { state: '<p>Aquas was one of three fragment planes formed after the Plane of Origins fractured in 1428 TC. An eternal Sea of Storms brews on the far eastern edge of the lands that make up this plane. As of yet, no one has been able to venture very far in without suffering heavy losses.</p>', revisionNumber: -1 },
  }
  locationOpen = 0;
  creature = {
    id: -1,
    name: { state: 'The Manager' },
    size: { state: 'Large' },
    type: { state: 'Sentient Plant' },
    details: { state: '<p>The Manager is a sentient shrub which has grown over time to cover the entirety of the ' +
          'south facing wall in the party\'s tavern. He has since learned how to take on a humanoid form in order to' +
          ' better serve patrons of the tavern.</p>'}
  }
  loreEntry = {
    id: -1,
    name: { state: 'Ley Lines', revisionNumber: -1 },
    details: { state: '<p>Ley lines (sometimes written as leylines) are hypothetical alignments between places of power, which may be magical, magnetic, or psychic in nature. These places of power are where two or more ley lines cross and are often known as Ley Nodes, Nodes, or Nexuses. Nodes are often regarded as unearthly. The Order hall is said to be built on one such intersection.</p>', revisionNumber: -1 },
  }
  carousel: number = 0;

  routeToUpgrade() {
    this.$router.push('/upgrade');
  }

  routeToSignup() {
    this.$router.push('/signup');
  }

  get numEntriesPerLine(): number {
    const screenSize = this.$vuetify.breakpoint.name
    if (screenSize === 'xs') {
      return 1
    } else {
      return 2
    }
  }

  get cardWidth(): string {
    return `${Math.round(100 / this.numEntriesPerLine)}%`
  }

  get cardWidthBlog(): string {
    return `${Math.round(100 / this.numEntriesPerLine) - 2}%`
  }

}
