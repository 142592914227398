import {Action, Module, Mutation, VuexModule} from "vuex-module-decorators";
import {Organization as OrganizationData} from "@/api/OrganizationApi";

@Module({ namespaced: true, name: 'organization' })
export default class Organization extends VuexModule {
    public organizations: Record<number, OrganizationData[]> = {};

    @Mutation
    public setOrganizations(organizations: Record<number, OrganizationData[]>): void {
        this.organizations = organizations;
    }

    @Action
    public updateOrganizations(organizations: Record<number, OrganizationData[]>): void {
        this.context.commit('setOrganizations', organizations);
    }

}
