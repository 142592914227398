import {authenticated} from "@/api/hooks";
import axios, {AxiosResponse} from "axios";

export type Person = {
    id: number,
    name: VersionedDoc,
    age: VersionedDoc,
    gender: VersionedDoc,
    role: VersionedDoc,
    race: VersionedDoc,
    location: VersionedDoc,
    project: number,
    portrait: { id: number },
    details: VersionedDoc,
    backstory: VersionedDoc,
    goals: VersionedDoc,
    visibilityLevel: string
}
export type VersionedDoc = { state: string, revisionNumber: number };
export const PLACEHOLDER_PERSON: Person = {
    id: -1,
    name: {state:"", revisionNumber:0},
    age: {state:"", revisionNumber:0},
    gender: {state:"", revisionNumber:0},
    role: {state:"", revisionNumber:0},
    race: {state:"", revisionNumber:0},
    details: {state:"", revisionNumber:0},
    backstory: {state:"", revisionNumber:0},
    goals: {state:"", revisionNumber:0},
    location: {state:"", revisionNumber:0},
    project: -1,
    portrait: { id: -1 },
    visibilityLevel: 'VISIBLE_TO_ALL'
}

const apiImage = authenticated(
    axios.create({
        baseURL: `${process.env.VUE_APP_HOSTNAME}/api/person`,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data'
        }
    })
);
const api = authenticated(
    axios.create({
        baseURL: `${process.env.VUE_APP_HOSTNAME}/api/person`,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
    })
)
class PersonApi {

    create(person: Person, gmOnly: boolean, image: Blob | null): Promise<void> {
        person.visibilityLevel = gmOnly ? 'GM_ONLY' : 'VISIBLE_TO_ALL';
        const form = new FormData()
        if (image !== null) {
            form.append('image', image, 'portrait-' + Date.now() + '.jpeg');
        }
        form.append(
            'person',
            new Blob([JSON.stringify(person)], { type: "application/json" })
        );
        return apiImage.post('/create', form);
    }

    update(person: Person, isGmLayerUpdate: boolean): Promise<void> {
        return api.post('/update', { person: person, isGmLayerUpdate: isGmLayerUpdate });
    }

    list(): Promise<Record<number, Person[]>> {
        return apiImage.get('/list', {}).then((result: AxiosResponse<Record<number, Person[]>>) => {
            return result.data;
        });
    }

    delete(id: number): Promise<void> {
        return api.post('/delete', { id: id });
    }

}
export default new PersonApi();
