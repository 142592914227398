import Vue from 'vue'
import VueRouter, {NavigationGuardNext, Route} from 'vue-router'
import usersApi from "@/api/UsersApi";
import notificationUtil from "@/notifications/NotificationUtil";
import store from "@/store";
// @ts-ignore
import Meta from 'vue-meta';
import ProjectsView from "@/views/projects/ProjectsView.vue";
import HomeView from "@/views/HomeView.vue";

Vue.use(VueRouter)
Vue.use(Meta);


const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomeView,
    authenticated: false,
  },
  {
    path: '/upgrade',
    name: 'Upgrade',
    component: () => import('@/views/UpgradeView.vue'),
    authenticated: false
  },
  {
    path: '/account',
    name: 'My Account',
    component: () => import('@/views/user/MyAccountView.vue'),
    authenticated: true
  },
  {
    path: '/privacy',
    name: 'Privacy Policy',
    component: () => import('@/views/PrivacyPolicy.vue'),
    authenticated: false
  },
  {
    path: '/about',
    name: 'About Us',
    component: () => import('@/views/AboutView.vue'),
    authenticated: false
  },
  {
    path: '/disclaimer',
    name: 'Disclaimer',
    component: () => import('@/views/DisclaimerView.vue'),
    authenticated: false
  },
  {
    path: '/terms',
    name: 'Terms of Use',
    component: () => import('@/views/TermsView.vue'),
    authenticated: false
  },
  {
    path: '/contact',
    name: 'Contact Us',
    component: () => import('@/views/ContactView.vue'),
    authenticated: false
  },
  {
    path: '/blog/about',
    name: 'About Lore 20',
    component: () => import('@/views/blog/WhatIsLore20.vue'),
    authenticated: false
  },
  {
    path: '/blog/getting-started',
    name: 'Getting Started',
    component: () => import('@/views/blog/GettingStarted.vue'),
    authenticated: false
  },
  {
    path: '/recover-password',
    name: 'Recover Password',
    component: () => import('@/views/user/StartPasswordRecoverView.vue'),
    authenticated: false
  },
  {
    path: '/recover/:recoveryId',
    name: 'Finish Recover Password',
    component: () => import('@/views/user/FinishPasswordRecoveryView.vue'),
    authenticated: false,
    props: (route: any) => {
      const recoveryId = route.params.recoveryId;
      return { recoveryId }
    }
  },
  {
    path: '/games',
    name: 'Games',
    component: ProjectsView,
    authenticated: true
  },
  {
    path: '/games/:id',
    name: 'Game',
    component: () => import('@/views/projects/ProjectView.vue'),
    authenticated: true,
    props: (route: any) => {
      const id = Number.parseInt(route.params.id, 10)
      if (Number.isNaN(id)) {
        return -1
      }
      return { id }
    }
  },
  {
    path: '/games/:projectId/characters',
    name: 'Characters',
    component: () => import('@/views/people/PeopleView.vue'),
    authenticated: true,
    props: (route: any) => {
      const id = Number.parseInt(route.params.projectId, 10)
      if (Number.isNaN(id)) {
        return -1
      }
      return { projectId: id }
    }
  },
  {
    path: '/games/:projectId/characters/:personId',
    name: 'Character',
    component: () => import('@/views/people/PersonView.vue'),
    authenticated: true,
    props: (route: any) => {
      const projectId = Number.parseInt(route.params.projectId, 10);
      const personId = Number.parseInt(route.params.personId, 10);
      if (Number.isNaN(projectId) || Number.isNaN(personId)) {
        return -1
      }
      return { projectId: projectId, personId: personId }
    }
  },
  {
    path: '/games/:projectId/sessions',
    name: 'Sessions',
    component: () => import('@/views/sessions/SessionsView.vue'),
    authenticated: true,
    props: (route: any) => {
      const id = Number.parseInt(route.params.projectId, 10)
      if (Number.isNaN(id)) {
        return -1
      }
      return { projectId: id }
    }
  },
  {
    path: '/games/:projectId/sessions/:sessionId',
    name: 'Session',
    component: () => import('@/views/sessions/SessionView.vue'),
    authenticated: true,
    props: (route: any) => {
      const projectId = Number.parseInt(route.params.projectId, 10);
      const sessionId = Number.parseInt(route.params.sessionId, 10);
      if (Number.isNaN(projectId) || Number.isNaN(sessionId)) {
        return -1
      }
      return { projectId: projectId, sessionId: sessionId }
    }
  },
  {
    path: '/games/:projectId/quests',
    name: 'Quests',
    component: () => import('@/views/quests/QuestsView.vue'),
    authenticated: true,
    props: (route: any) => {
      const id = Number.parseInt(route.params.projectId, 10)
      if (Number.isNaN(id)) {
        return -1
      }
      return { projectId: id }
    }
  },
  {
    path: '/games/:projectId/quests/:questId',
    name: 'Quest',
    component: () => import('@/views/quests/QuestView.vue'),
    authenticated: true,
    props: (route: any) => {
      const projectId = Number.parseInt(route.params.projectId, 10);
      const questId = Number.parseInt(route.params.questId, 10);
      if (Number.isNaN(projectId) || Number.isNaN(questId)) {
        return -1
      }
      return { projectId: projectId, questId: questId }
    }
  },
  {
    path: '/invite/:inviteId',
    name: 'Invite',
    component: () => import('@/views/InviteView.vue'),
    authenticated: false,
    props: (route: any) => {
      return { inviteId: route.params.inviteId }
    }
  },
  {
    path: '/games/:projectId/locations',
    name: 'Locations',
    component: () => import('@/views/locations/LocationsView.vue'),
    authenticated: true,
    props: (route: any) => {
      const id = Number.parseInt(route.params.projectId, 10)
      if (Number.isNaN(id)) {
        return -1
      }
      return { projectId: id }
    }
  },
  {
    path: '/games/:projectId/locations/:locationId',
    name: 'Location',
    component: () => import('@/views/locations/LocationView.vue'),
    authenticated: true,
    props: (route: any) => {
      const projectId = Number.parseInt(route.params.projectId, 10)
      const locationId = Number.parseInt(route.params.locationId, 10)

      if (Number.isNaN(projectId) || Number.isNaN(locationId)) {
        return -1
      }
      return { projectId: projectId, locationId: locationId }
    }
  },
  {
    path: '/games/:projectId/organizations',
    name: 'Organizations',
    component: () => import('@/views/organizations/OrganizationsView.vue'),
    authenticated: true,
    props: (route: any) => {
      const id = Number.parseInt(route.params.projectId, 10)
      if (Number.isNaN(id)) {
        return -1
      }
      return { projectId: id }
    }
  },
  {
    path: '/games/:projectId/organizations/:organizationId',
    name: 'Organization',
    component: () => import('@/views/organizations/OrganizationView.vue'),
    authenticated: true,
    props: (route: any) => {
      const projectId = Number.parseInt(route.params.projectId, 10)
      const organizationId = Number.parseInt(route.params.organizationId, 10)

      if (Number.isNaN(projectId) || Number.isNaN(organizationId)) {
        return -1
      }
      return { projectId: projectId, organizationId: organizationId }
    }
  },
  {
    path: '/games/:projectId/creatures',
    name: 'Creatures',
    component: () => import('@/views/creatures/CreaturesView.vue'),
    authenticated: true,
    props: (route: any) => {
      const id = Number.parseInt(route.params.projectId, 10)
      if (Number.isNaN(id)) {
        return -1
      }
      return { projectId: id }
    }
  },
  {
    path: '/games/:projectId/creatures/:creatureId',
    name: 'Creature',
    component: () => import('@/views/creatures/CreatureView.vue'),
    authenticated: true,
    props: (route: any) => {
      const projectId = Number.parseInt(route.params.projectId, 10)
      const creatureId = Number.parseInt(route.params.creatureId, 10)

      if (Number.isNaN(projectId) || Number.isNaN(creatureId)) {
        return -1
      }
      return { projectId: projectId, creatureId: creatureId }
    }
  },
  {
    path: '/games/:projectId/deities',
    name: 'Deities',
    component: () => import('@/views/deities/DeitiesView.vue'),
    authenticated: true,
    props: (route: any) => {
      const id = Number.parseInt(route.params.projectId, 10)
      if (Number.isNaN(id)) {
        return -1
      }
      return { projectId: id }
    }
  },
  {
    path: '/games/:projectId/deities/:deityId',
    name: 'Deity',
    component: () => import('@/views/deities/DeityView.vue'),
    authenticated: true,
    props: (route: any) => {
      const projectId = Number.parseInt(route.params.projectId, 10)
      const deityId = Number.parseInt(route.params.deityId, 10)
      if (Number.isNaN(projectId) || Number.isNaN(deityId)) {
        return -1
      }
      return { projectId: projectId, deityId: deityId }
    }
  },
  {
    path: '/games/:projectId/lore',
    name: 'Lore Entries',
    component: () => import('@/views/lore/LoreEntriesView.vue'),
    authenticated: true,
    props: (route: any) => {
      const id = Number.parseInt(route.params.projectId, 10)
      if (Number.isNaN(id)) {
        return -1
      }
      return { projectId: id }
    }
  },
  {
    path: '/games/:projectId/lore/:loreEntryId',
    name: 'Lore Entry',
    component: () => import('@/views/lore/LoreEntryView.vue'),
    authenticated: true,
    props: (route: any) => {
      const projectId = Number.parseInt(route.params.projectId, 10)
      const loreEntryId = Number.parseInt(route.params.loreEntryId, 10)
      if (Number.isNaN(projectId) || Number.isNaN(loreEntryId)) {
        return -1
      }
      return { projectId: projectId, loreEntryId: loreEntryId }
    }
  },
]

const router = new VueRouter({
  mode: "history",
  routes
})

const canAccess = (arg: Route): boolean => {
  return !routes.find((item) => item?.name === arg.name)?.authenticated ?? false;
}

router.beforeEach(async (to, from, next) => {
  if (!canAccess(to) && to.path !== '/signup') {
    try {
      await usersApi.info();
      goToNext(to, next)
    } catch (e) {
      const refreshToken = store.state.User.refresh;
      usersApi.refresh(refreshToken).then((response: any) => {
        store.state.User.token = response.accessToken;
        store.state.User.refresh = response.refreshToken;
        goToNext(to, next);
      }).catch(() => {
        store.state.User.token = "";
        store.state.User.refresh = "";
        notificationUtil.info('Sign in to continue');
        router.push(
            {
              'name': 'Home',
              'params': {
                'forwardRoute': window.location.href
              }
            }
        );
      });
    }
  } else {
    goToNext(to, next);
  }
});

const goToNext = (to: Route, next: NavigationGuardNext<Vue>) => {
  const tokens = to.hash.split('#')
  if (tokens.length > 1) {
    next({
      path: tokens[1],
      hash: '',
      replace: true
    });
  } else {
    next();
  }
}

export default router