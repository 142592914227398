import {Action, Module, Mutation, VuexModule} from "vuex-module-decorators";
import {Location as LocationData} from "@/api/LocationApi";

@Module({ namespaced: true, name: 'location' })
export default class Location extends VuexModule {
    public locations: Record<number, LocationData[]> = {};

    @Mutation
    public setLocations(locations: Record<number, LocationData[]>): void {
        this.locations = locations;
    }

    @Action
    public updateLocations(locations: Record<number, LocationData[]>): void {
        this.context.commit('setLocations', locations);
    }

}
