import {authenticated} from "@/api/hooks";
import axios, {AxiosResponse} from "axios";
import {VersionedDoc} from "@/api/PersonApi";

export type Session = {
    id: number,
    title: VersionedDoc,
    date: VersionedDoc,
    body: VersionedDoc,
    project: number,
    visibilityLevel: string
}
export const PLACEHOLDER_SESSION = {
    id: -1,
    title: { state: "", revisionNumber: -1 },
    date: { state: "", revisionNumber: -1 },
    body: { state: "", revisionNumber: -1 },
    project: -1,
    visibilityLevel: 'VISIBLE_TO_ALL'
}

const session = authenticated(
    axios.create({
        baseURL: `${process.env.VUE_APP_HOSTNAME}/api/session`
    })
);
class SessionApi {

    create(projectId: number, title: string, date: string, gmOnly: boolean): Promise<void> {
        return session.post('/create', { title: title, date: date, project: projectId, visibilityLevel: (gmOnly) ? 'GM_ONLY' : 'VISIBLE_TO_ALL' })
    }

    list(): Promise<Record<number, Session[]>> {
        return session.get('/list').then((result: AxiosResponse<Record<number, Session[]>>) => {
            return result.data;
        });
    }

    update(sessionData: Session, isGmLayerUpdate: boolean): Promise<void> {
        return session.post('/update', { session: sessionData, isGmLayerUpdate: isGmLayerUpdate });
    }

    delete(id: number): Promise<void> {
        return session.post('/delete', { id: id });
    }

}

export default new SessionApi();
