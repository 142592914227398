import axios, {AxiosResponse} from "axios";
import {authenticated} from "@/api/hooks";
import {Project} from "@/api/ProjectApi";

const commerce = authenticated(
    axios.create({
        baseURL: `${process.env.VUE_APP_HOSTNAME}/api/commerce`
    })
);
class CommerceApi {

    checkoutSession(): Promise<string> {
        return commerce.post('/checkout-session', { lookupKey: 'premium' }).then((res: any) => {
            return res.data
        });
    }

    isSubscribed(): Promise<boolean> {
        return commerce.get('/subscription-status').then((response: AxiosResponse<boolean>) => {
            return response.data;
        })
    }

    cancelSubscription(projects: number[]): Promise<void> {
        return commerce.post('/downgrade', { projects });
    }
}
export default new CommerceApi();
