




























































































































































































































import {namespace} from "vuex-class";
import Component from "vue-class-component";
import {Prop, Vue, Watch} from "vue-property-decorator";
import SignupView from "@/views/user/SignupView.vue";
// @ts-ignore
import CookieLaw from 'vue-cookie-law';
import commerceApi from "@/api/CommerceApi";
import {Lore20User} from "@/api/UsersApi";
const user = namespace("User");
const commerce = namespace('Commerce');

// @ts-ignore
@Component({
  components: {SignupView, CookieLaw},
  metaInfo() {
    return {
      title: 'Lore 20 - Simplify Your World',
      script: [
        {
          src: 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js',
          async: true,
          'data-ad-client': 'ca-pub-7762404773983448'
        }
      ]
    }
  }
})
export default class App extends Vue {

  @user.Getter
  isLoggedIn!: boolean

  @user.State
  username!: string

  @user.State
  user!: Lore20User;

  @user.Mutation
  logoutUser!: () => void;

  @commerce.State
  subscribed!: boolean;

  @commerce.Mutation
  setSubscription!: (subscribed: boolean) => void; // eslint-disable-line

  @Prop({ required: false, default: "" }) message!: string

  drawer = false;
  showSignupModal1 = false;
  showSignupModal2 = false;
  showAds = false;
  upsellIndex = 0;
  navStep = 0;

  logoWebp = require('./assets/logo.webp')

  @Watch("$route.path")
  onScreenChange() {
    this.navStep += 1;
    if (this.isLoggedIn) {
      commerceApi.isSubscribed().then((isSubscribed) => {
        this.setSubscription(isSubscribed);
        this.showAds = !isSubscribed;
      });
    } else {
      this.showAds = true;
    }
    this.upsellIndex += 1;
    if (this.upsellIndex > 3) {
      this.upsellIndex = 0;
    }
  }

  get viewMode(): string {
    const screenSize = this.$vuetify.breakpoint.name
    if (screenSize === 'xs' || screenSize == 'sm') {
      return 'mobile';
    } else {
      return 'desktop';
    }
  }

  get isInviteWorkflow() {
    return this.$route.name === 'Invite';
  }

  goToUpgrade() {
    this.$router.push('/upgrade');
  }

}
