

































































import {Prop, Vue} from "vue-property-decorator";
import Component from "vue-class-component";
import {Location} from "@/api/LocationApi";

@Component
export default class LocationCard extends Vue {
  @Prop({ required: true }) location!: Location;
  @Prop({ required: true }) icon!: string;
  @Prop({ required: false }) imageUrl!: string;
}
