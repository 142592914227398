import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Toast from "vue-toastification";
import VueJSModal from "vue-js-modal";
import "vue-toastification/dist/index.css";
import 'vue-js-modal/dist/styles.css';
import Vuetify from 'vuetify/lib'
import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";
import VueGtag from "vue-gtag";
import {Route} from "vue-router";
import '@mdi/font/css/materialdesignicons.min.css'
import Ads from "vue-google-adsense";
// @ts-ignore
import VS2 from 'vue-script2';
import Quill from "quill";
import QuillCursors from "quill-cursors";

Vue.config.productionTip = false

Vue.use(Vuetify)
Vue.use(VS2);
Vue.use(Ads.Adsense);
Vue.use(Ads.InArticleAdsense);
Vue.use(Ads.InFeedAdsense);

const vuetify = new Vuetify({
  theme: { dark: true },
  defaultAssets: false,
  icons: {
    iconfont: 'mdi'
  }
});

Quill.register('modules/cursors', QuillCursors);

if (process.env.VUE_APP_ENVIRONMENT !== 'development') {
  const ignorableErrors = new RegExp(
      `.*Request failed with status code 4[0-9]+|.*NavigationDuplicated: Avoided redundant navigation to current location:.*|.*Navigation cancelled from .* to .*`,
      'mi'
  );
  Sentry.init({
    Vue,
    dsn: "https://7d778b68a90a46f6ac556004331302f8@o1268018.ingest.sentry.io/6454931",
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: [process.env.VUE_APP_HOSTNAME, /^\//],
      }),
    ],
    tracesSampleRate: 1.0,
    environment: process.env.VUE_APP_ENVIRONMENT,
    tracingOptions: {
      trackComponents: true
    },
    release: 'lore20-client@' + process.env.VUE_APP_VERSION,
    autoSessionTracking: true,
    ignoreErrors: [ignorableErrors]
  })
}



router.onReady(() => {
  Vue.use(VueGtag, {
    config: {
      id: 'G-JQBZ731FNV'
    },
    appName: 'Lore 20',
    pageTrackerScreenviewEnabled: true,
    pageTrackerTemplate(to: Route) {
      return {
        page_title: to.name,
        page_path: to.path
      }
    }
  }, router)
});

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')

Vue.use(Toast);
Vue.use(VueJSModal);
