




























import {Prop, Vue} from "vue-property-decorator";
import {Session} from "@/api/SessionApi";
import Component from "vue-class-component";

@Component
export default class SessionCard extends Vue {

  @Prop({ required: true }) projectId!: number;
  @Prop({ required: true }) session!: Session;

  goToSession(sessionId: number) {
    this.$router.push(`/games/${this.projectId}/sessions/${sessionId}`);
  }

}
