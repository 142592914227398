import {authenticated} from "@/api/hooks";
import axios, {AxiosResponse} from "axios";

const api = authenticated(
    axios.create({
        baseURL: `${process.env.VUE_APP_HOSTNAME}/api/image`,
        headers: {
            'Accept': 'application/json'
        }
    })
);
const apiImage = authenticated(
    axios.create({
        baseURL: `${process.env.VUE_APP_HOSTNAME}/api/image`,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data'
        }
    })
);
export enum UpdateType {
    PERSON = "PERSON",
    CREATURE = "CREATURE",
    DEITY = "DEITY",
    LOCATION = "LOCATION",
    ORGANIZATION = "ORGANIZATION"
}
class ImageApi {

    get(id: number): Promise<string> {
        return api.get('/' + id, {}).then((res: AxiosResponse<string>) => {
            return res.data;
        });
    }

    update(projectId: number, imageId: number, image: Blob | null): Promise<void> {
        const form = new FormData()
        if (image !== null) {
            form.append('image', image, 'org-image-' + Date.now() + '.jpeg');
        }
        return apiImage.post('/update/' + projectId + '/' + imageId, form)
    }

    updateNew(projectId: number, updateType: string, entityId: number, image: Blob | null): Promise<number> {
        const form = new FormData()
        if (image !== null) {
            form.append('image', image, 'org-image-' + Date.now() + '.jpeg');
        }
        return apiImage.post(
            '/update/' + projectId + '/' + updateType + '/' + entityId,
            form
        ).then((res: AxiosResponse<number>) => {
            return res.data;
        });
    }
}
export default new ImageApi();
