import {Action, Module, Mutation, VuexModule} from "vuex-module-decorators";
import {Creature as CreatureData} from "@/api/CreatureApi";

@Module({ namespaced: true, name: 'creature' })
export default class Creature extends VuexModule {
    public creatures: Record<number, CreatureData[]> = {};

    @Mutation
    public setCreatures(creatures: Record<number, CreatureData[]>): void {
        this.creatures = creatures;
    }

    @Action
    public updateCreatures(creatures: Record<number, CreatureData[]>): void {
        this.context.commit('setCreatures', creatures);
    }

}
